// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-mdx-post-js": () => import("/home/notplants/computer/projects/canalswans.net/src/templates/mdx-post.js" /* webpackChunkName: "component---src-templates-mdx-post-js" */),
  "component---src-pages-404-js": () => import("/home/notplants/computer/projects/canalswans.net/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/home/notplants/computer/projects/canalswans.net/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("/home/notplants/computer/projects/canalswans.net/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-misc-js": () => import("/home/notplants/computer/projects/canalswans.net/src/pages/misc.js" /* webpackChunkName: "component---src-pages-misc-js" */),
  "component---src-pages-wiki-js": () => import("/home/notplants/computer/projects/canalswans.net/src/pages/wiki.js" /* webpackChunkName: "component---src-pages-wiki-js" */),
  "component---src-pages-writing-js": () => import("/home/notplants/computer/projects/canalswans.net/src/pages/writing.js" /* webpackChunkName: "component---src-pages-writing-js" */),
  "component---src-pages-zines-js": () => import("/home/notplants/computer/projects/canalswans.net/src/pages/zines.js" /* webpackChunkName: "component---src-pages-zines-js" */)
}

